import { Components, createTheme } from '@mui/material'
import { border } from './border'
import { color, xxllncColor } from './color'

const material = createTheme()

export const muiOverrides: Components = {
  MuiTypography: {
    styleOverrides: {
      root: {
        ['&.MuiTypography-subtitle1']: {
          fontWeight: 'bold',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        border: 0,
        backgroundClip: 'padding-box',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        ['& svg']: {
          marginRight: 10,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        [material.breakpoints.down('sm')]: {
          backgroundColor: xxllncColor.base,
        },
        [material.breakpoints.down('lg')]: {
          padding: material.spacing(0, 3),
        },
        [material.breakpoints.up('md')]: {
          padding: material.spacing(0, 6),
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        color: xxllncColor.dark60,
        borderRadius: border.button.radius,
        minWidth: 'auto',
      },
      contained: {
        backgroundColor: color.white,
        color: xxllncColor.mineShaft,
        boxShadow: 'none',
      },
      outlinedPrimary: {
        border: border.normal + ' solid ' + border.dark,
        ['&:hover']: {
          border: border.normal + ' solid ' + border.dark,
        },
      },
      startIcon: {
        marginRight: -2,
        marginLeft: 8,
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        ['&:hover:active::after']: {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
        ['&.MuiButton-contained']: {
          ['&:hover']: {
            backgroundColor: xxllncColor.dark10,
          },
        },
      },
    },
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        marginBottom: material.spacing(2),
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: xxllncColor.base,
        border: 0,
        borderBottom: border.normal + ' solid ' + border.light,
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: xxllncColor.alto,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: border.input.radius,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        display: 'none',
      },
    },
  },
  MuiGrid: {
    styleOverrides: {
      root: {
        ['& .ra-field']: {
          display: 'flex',
          padding: material.spacing(.5),
          ['& a']: {
            wordWrap: 'break-word',
          },
        },
      },
      container: {
        ['&.MuiGrid-spacing-xs-1']: {
          marginTop: material.spacing(2),
        },
        ['&.MuiGrid-spacing-xs-2']: {
          marginTop: material.spacing(2),
        },
        ['&.MuiGrid-spacing-xs-3']: {
          marginTop: material.spacing(2),
        }
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        background: xxllncColor.light80,
        border: border.thin + ' solid ' + xxllncColor.dark10,
        borderRadius: border.dropdown.radius,
        boxShadow: '0px 4px 8px rgba(29, 29, 29, 0.04), 0px 8px 16px rgba(29, 29, 29, 0.02)',
        ['& .MuiMenu-list']: {
          boxSizing: 'border-box',
          padding: material.spacing(2, 1.5),
          ['& .MuiMenuItem-root']: {
            borderRadius: border.radius.md,
            color: xxllncColor.dark80,
            marginBottom: 0,
            marginTop: material.spacing(1),
            width: '100%',
            ['& .MuiSvgIcon-root']: {
              fill: xxllncColor.dark80,
            },
            ['&:first-child']: {
              marginTop: 0,
            },
            ['&:hover']: {
              color: xxllncColor.dark100,
              background: xxllncColor.opDark10,
              ['& .MuiSvgIcon-root']: {
                fill: xxllncColor.dark100,
              },
            },
          },
          ['& .Mui-selected']: {
            background: xxllncColor.dark80,
            color: xxllncColor.light100,
            ['& .MuiSvgIcon-root']: {
              fill: xxllncColor.light100,
            },
          },
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        minHeight: '180px'
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        ['& p']: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      },
    },
  },
}
